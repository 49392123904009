import React from 'react'

function InfosTeeshirt() {
    return (
        <div>
            <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg mt-14 mb-8 text-center'>Fonctionnement du jeu</h1>
            <div className='flex overflow-x-auto snap-x snap-mandatory no-scrollbar mb-8 mx-auto 2xl:justify-center'>
            <div className='shrink-0 max-[430px]:w-[17.5%]'></div>  {/* Ajoute du padding à droite */}
                <div className='flex flex-col justify-center text-center text-xl font-bold snap-center shrink-0 mx-4 sm:mx-8 max-[430px]:w-[65%] max-[430px]:min-w-[250px] w-[250px]'>
                    <div className='relative rounded-lg overflow-hidden'>
                        <h2 className=' w-full text-white bg-black py-1'>Etape 1</h2>
                        <img src="/images/teeshirt/step1.webp" alt="" />
                        <h2 className='absolute bottom-0 w-full text-white bg-black py-1 px-2'>Cherche Charlie dans la fête votive</h2>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-center text-xl font-bold snap-center shrink-0 mx-4 sm:mx-8 max-[430px]:w-[65%] max-[430px]:min-w-[250px] w-[250px]'>
                    <div className='relative rounded-lg overflow-hidden'>
                        <h2 className=' w-full text-white bg-black py-1'>Etape 2</h2>
                        <img src="/images/teeshirt/step2.webp" alt="" />
                        <h2 className='absolute bottom-0 w-full text-white bg-black py-1 px-2'>Trouve Charlie dans la fête votive</h2>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-center text-xl font-bold snap-center shrink-0 mx-4 sm:mx-8 max-[430px]:w-[65%] max-[430px]:min-w-[250px] w-[250px]'>
                    <div className='relative rounded-lg overflow-hidden'>
                        <h2 className=' w-full text-white bg-black py-1'>Etape 3</h2>
                        <img src="/images/teeshirt/step3.webp" alt="" />
                        <h2 className='absolute bottom-0 w-full text-white bg-black py-1 px-2'>On coche ton tee-shirt la bonne case</h2>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-center text-xl font-bold snap-center shrink-0 mx-4 sm:mx-8 max-[430px]:w-[65%] max-[430px]:min-w-[250px] w-[250px]'>
                    <div className='relative rounded-lg overflow-hidden'>
                        <h2 className=' w-full text-white bg-black py-1'>Etape 4</h2>
                        <img src="/images/teeshirt/step4.webp" alt="" />
                        <h2 className='absolute bottom-0 w-full text-white bg-black py-1 px-2'>On prend une photo de la case</h2>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-center text-xl font-bold snap-center shrink-0 mx-4 sm:mx-8 max-[430px]:w-[65%] max-[430px]:min-w-[250px] w-[250px]'>
                    <div className='relative rounded-lg overflow-hidden'>
                        <h2 className=' w-full text-white bg-black py-1'>Etape 5</h2>
                        <img src="/images/teeshirt/step5.webp" alt="" />
                        <h2 className='absolute bottom-0 w-full text-white bg-black py-1 px-2'>Retrouve la photo dans la story à la une</h2>
                    </div>
                </div>
                <div className='shrink-0 max-[430px]:w-[17.5%] w-[1px] mr-4'></div>  {/* Ajoute du padding à droite */}
            </div>
            <div className='flex flex-col w-[90%] md:w-[80%] lg:w-[60%] m-auto mb-8 infoTee'>
                <p className='text-left'><span className='font-bold'>Achat et participation:</span> En achetant un tee-shirt sur <a href="https://www.fetevotive.fr" className='text-blue-600'>fetevotive.fr</a> au prix de 15 € l'unité, vous êtes automatiquement inscrit à un jeu concours.
                    <br />Ce jeu vous donne la chance de gagner des boissons lors des fêtes votives de sept villages ardéchois durant l'été 2024.
                </p>
                <div className='h-5'></div>
                <p><span className='font-bold'>Principe du jeu:</span>  Le dos de chaque tee-shirt affiche une carte avec les noms et les emplacements de sept villages. À côté de chaque village, une case est à cocher.
                    Votre objectif est d'assister aux fêtes votives indiquées sur la carte, de trouver notre équipe au sein des fêtes votives et de faire cocher les cases correspondantes sur votre tee-shirt. Seule l'équipe de fetevotive.fr est habilitée à authentifier une coche sur votre tee-shirt. Les récompenses varient selon le nombre de cases cochées:</p>
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Lot 1:</span> Un verre de marquisette ou un soda.</li>
                    <li className='list-disc'><span className='font-bold'>Lot 2:</span> Une bouteille de marquisette ou deux sodas.</li>
                </ul>
                <div className='h-5'></div>
                <p><span className='font-bold'>Choix exclusif du lot: </span>Vous ne pouvez pas cumuler les lots. Une fois que vous choisissez de réclamer le <span className='font-bold'>Lot 1</span>, vous ne pourrez pas réclamer le <span className='font-bold'>Lot 2</span>, et inversement. Chaque participant peut réclamer seulement un lot durant la période du jeu.</p>
                <div className='h-5'></div>
                <p><span className='font-bold'>Modalités de réclamation:</span></p>
                <p>Une fois votre choix fait, un <span className='font-bold'>ticket</span> vous sera remis, indiquant le lot choisi. Présentez-vous au bar avec ce ticket pour réclamer votre récompense.</p>
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Le Lot 1 </span>peut être réclamé une seule et unique fois lors d'une des fêtes votives présente sur le tee-shirt une fois trois cases validées, entre 21h et 1h du matin.</li>
                    <li className='list-disc'><span className='font-bold'>Le Lot 2 </span>est disponible après validation des sept cases, lors de la dernière fête votive inscrite sur votre tee-shirt à Lablachère, aussi entre 21h et 1h du matin.</li>
                </ul>
                <div className='h-5'></div>
                <p><span className='font-bold'>Règles importantes:</span></p>
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Un tee-shirt par participant: </span>Chaque participant peut utiliser uniquement un tee-shirt pour le jeu. Il n'est pas permis d'acheter et/ou d'utiliser plusieurs tee-shirts pour accumuler des coches ou réclamer plusieurs lots. Une personne équivaut à un tee-shirt.</li>
                    <li className='list-disc'><span className='font-bold'>Non-transférabilité: </span>Il n'est également pas possible de prêter son tee-shirt à une autre personne pour le faire cocher à sa place. Chaque tee-shirt est personnel et non transférable.</li>
                </ul>
                <div className='h-5'></div>
                <p className=' text-red-600'><span className='font-bold text-red-600'>Important:</span> L'alcool est réservé aux participants majeurs. Une pièce d'identité pourra être demandée pour récupérer les lots contenant de l'alcool. Les lots sans alcool seront attribués aux mineurs ou aux personnes ne souhaitant pas consommer d'alcool.</p>
                <div className='h-5'></div>
                <p className='font-bold'>Comment obtenir une case cochée:</p>
                <ol className='pl-8'>
                    <li className='list-decimal'><span className='font-bold'>Identification: </span>Localisez un membre de notre équipe, identifiable à son tee-shirt rayé rouge et blanc, à l'image de Charlie du fameux jeu "Où est Charlie".</li>
                    <li className='list-decimal'><span className='font-bold'>Validation: </span>Seule l'équipe de fetevotive.fr est habilitée à authentifier une coche sur votre tee-shirt. Le membre de l'équipe utilisera un marqueur indélébile spécial textile pour cocher la case de la fête votive visitée sur votre tee-shirt. <span className='font-semibold'>Il est impératif que le tee-shirt soit porté par le participant au moment de la validation. </span> Une photo de la case cochée avec le numéro du tee-shirt sera prise et publiée sur le compte Instagram de fetevotive.fr dans la "story à la une" du tee-shirt en question pour authentifier officiellement la validation (La "story à la une" aura comme nom le numéro du tee-shirt).</li>
                </ol>
                <div className='h-5'></div>
                <p><span className='font-semibold'>Note:</span> Suivez notre compte <a target='_blank' rel="noreferrer" href='https://www.instagram.com/fetevotive.fr/' className="w-[200px] my-2 hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-600 border border-blue-600 text-white hover:text-blue-600 font-bold py-1 px-2 rounded duration-300 ">Instagram</a> pour faciliter la localisation de notre équipe durant les fêtes et pour vérifier les validations.</p>
                <div className='h-5'></div>
                <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg mt-14 mb-8 text-center'>Dates et heures pour faire cocher les tee-shirts</h1>
                <p>L'équipe de fetevotive.fr sera présente uniquement à ces dates et horaires pour faire cocher et valider tee-shirt:</p>
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Joyeuse: </span>12 et 13 juillet 2024 de 20h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Saint-Jean-le-Centenier: </span>19 juillet 2024 de 21h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Saint-Etienne-de-Boulogne: </span>20 juillet 2024 de 21h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Laurac-en-Vivarais: </span>9 et 10 août 2024 de 21h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Jaujac: </span>14 et 18 août 2024 de 21h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Rosières: </span>16 et 17 août 2024 de 21h à 1h du matin</li>
                    <li className='list-disc'><span className='font-bold'>Fête votive de Lablachère: </span>30 et 31 août 2024 de 21h à 1h du matin</li>
                </ul>
                <div className='h-5'></div>
                <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg mt-14 mb-8 text-center'>Commande de tee-shirts</h1>
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Procédure: </span>Remplissez le <p onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="cursor-pointer w-[200px] my-2 hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-600 border border-blue-600 text-white hover:text-blue-600 font-bold py-1 px-2 rounded duration-300 inline">formulaire</p> de commande sur notre site.</li>
                    <li className='list-disc'><span className='font-bold'>Commandes en gros: </span>Pour les commandes de plus de neuf tee-shirts de la même taille, spécifiez le nombre et la taille souhaités dans votre message.</li>
                    <li className='list-disc'><span className='font-bold'>Confirmation et paiement: </span>Nous vous contacterons pour finaliser la livraison et le paiement. Le paiement se fait à la réception par PayPal, Paylib, virement instantané ou en espèces. Aucun prépaiement requis.</li>
                </ul>
                <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg mt-14 mb-8 text-center'>Options de livraison</h1>
                {/* <ul className='pl-8'>
                <li className='list-disc'><span className='font-bold'>Livraison à domicile: </span>Supplément de 3 € pour les livraisons dans les villes spécifiées (Aubenas, Joyeuse, Rosières, Lablachère, Laurac-en-Vivarais, Largentière, Vogüé, Ruoms, Vals-les-Bains, Lavilledieu et Vinezac).</li>
                <li className='list-disc'><span className='font-bold'>Retrait en fête votive: </span>Retrait sans supplément à toutes les fêtes votives présentes sur le tee-shirt entre 21h et 1h du matin.</li>
                <li className='list-disc'><span className='font-bold'>Autre: </span>Vous pouvez nous demander un lieu de livraison dans votre message lors de la commande, l'équipe de <a href="https://www.fetevotive.fr" className='text-blue-600'>fetevotive.fr</a> reviendra vers vous pour vous confirmer ou non la possibilité de livraison.</li>
            </ul> */}
                <ul className='pl-8'>
                    <li className='list-disc'><span className='font-bold'>Livraison à Aubenas: </span>Supplément de 2 € pour les livraisons dans la ville d'Aubenas 07200.</li>
                    <li className='list-disc'><span className='font-bold'>Retrait en fête votive: </span>Retrait sans supplément à toutes les fêtes votives présentes sur le tee-shirt entre 21h et 1h du matin.</li>
                    <li className='list-disc'><span className='font-bold'>Autre: </span>Vous pouvez nous demander un lieu de livraison dans votre message lors de la commande, l'équipe de <a href="https://www.fetevotive.fr" className='text-blue-600'>fetevotive.fr</a> reviendra vers vous pour vous confirmer ou non la possibilité de livraison.</li>
                </ul>
                <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg mt-14 mb-8 text-center'>Contact</h1>
                <p>
                    Pour toutes questions, vous pouvez nous contacter par téléphone au <a className='text-blue-600' href="tel:0644870471">0644870471</a> ou par e-mail à l'adresse: <a className='text-blue-600' href="mailto:fetevotive.fr@gmail.com">fetevotive.fr@gmail.com</a>
                </p>
            </div>
        </div>
    )
}

export default InfosTeeshirt