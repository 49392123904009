
export const data = [
    {
        id: 1,
        category: ['votive', 'petanque', 'aout', 'septembre'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Lablachère',
        date: '2024-08-30 14:00',
        endDate: '2024-09-02 17:00',
        dateShow: 'Du Vendredi 30 Août au Lundi 02 Septembre 2024 | Bal le 30 et 31',
        price: 'Gratuite',
        description: "Bal le 30 et 31 Août à partir de 21h. Concours de pétanque (date à venir).",
        compteInsta: 'https://www.instagram.com/lesvotiers/',
        alt: 'Affiche de la fête votive de Lablachère'
    },
    {
        id: 2,
        category: ['votive', 'petanque', 'aout'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Jaujac',
        date: '2024-08-13 14:00',
        endDate: '2024-08-19 03:00',
        dateShow: 'Du Mardi 13 Août au Dimanche 18 Août 2024 | Bal le 14, 15, 16, 17 et 18 Août',
        price: 'Gratuite',
        description: "Bal le 14, 15, 16, 17 et 18 Août à partir de 21h. Tournée des pognes le 13 et Course de caisse à savon le 16.",
        compteInsta: 'https://www.instagram.com/comite_des_jeunes_de_jaujac/',
        alt: 'Affiche de la fête votive de Jaujac'
    },
    {
        id: 3,
        category: ['votive', 'petanque', 'juillet'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Joyeuse',
        date: '2024-07-12 14:00',
        endDate: '2024-07-14 17:00',
        dateShow: 'Du Vendredi 12 au Dimanche 14 Juillet 2024 | Bal le 12 et 13 Juillet',
        price: 'Gratuite',
        description: "Bal le 12 et 13 Juillet à partir de 21h. Feu d'artifice le 13 Juillet.",
        compteInsta: 'https://www.instagram.com/jeunesdejoyeuse/',
        alt: 'Affiche de la fête votive de Joyeuse'
    },
    {
        id: 4,
        category: ['votive', 'petanque', 'aout'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Rosière',
        date: '2024-08-16 14:00',
        endDate: '2024-08-18 03:00',
        dateShow: 'Vendredi 16 et Samedi 17 Août 2024 | Bal les deux soirs',
        price: 'Gratuite',
        description: "Bal le 16 et 17 Juillet à partir de 21h.",
        compteInsta: 'https://www.instagram.com/les.jeunes.a.rose/',
        alt: 'Affiche de la fête votive de Rosière'
    },
    {
        id: 5,
        category: ['votive', 'petanque', 'juillet'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Laurac',
        date: '2024-08-09 14:00',
        endDate: '2024-08-11 03:00',
        dateShow: 'Vendredi 9 et Samedi 10 Août 2024 | Bal les deux soirs',
        price: 'Gratuite',
        description: "Bal le 9 et 10 Août à partir de 21h.",
        compteInsta: 'https://www.instagram.com/lesvotiersdelaurac/',
        alt: 'Affiche de la fête votive de Laurac'
    },
    {
        id: 6,
        category: ['votive', 'petanque', 'juillet'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Saint-Etienne-de-Boulogne',
        date: '2024-07-20 14:00',
        endDate: '2024-07-21 03:00',
        dateShow: 'Samedi 20 Juillet 2024',
        price: 'Gratuite',
        description: "Bal le 20 Juillet et concours de pétanque (date à venir)",
        compteInsta: 'https://www.instagram.com/lesvotiersdeste/',
        alt: 'Affiche de la fête votive de Saint-Etienne-de-Boulogne'
    },
    {
        id: 7,
        category: ['votive', 'petanque', 'juillet'],
        imageUrl: '/images/affiches/soon.webp',
        title: 'Fête Votive de Saint-Jean-le-Centenier',
        date: '2024-07-19 14:00',
        endDate: '2024-07-22 03:00',
        dateShow: 'Du Vendredi 19 au Dimanche 21 Juillet 2024',
        price: 'Gratuite',
        description: "Bal le 19, orchèstre le 20 et concours de pétanque (date à venir)",
        compteInsta: 'https://www.instagram.com/comite_des_jeunes_st_jean/',
        alt: 'Affiche de la fête votive de Saint-Jean-le-Centenier'
    },
    {
        id: 8,
        category: ['votive'],
        imageUrl: '/images/affiches/laurac-st-jean.webp',
        title: 'Bal de la Saint-Jean de Laurac-en-Vivarais',
        date: '2024-06-15 20:00',
        endDate: '2024-06-16 03:00',
        dateShow: 'Samedi 15 Juin 2024',
        price: 'Gratuite',
        description: "Bal le 15 à partir de 21h, petite restauration à partir de 19h.",
        compteInsta: 'https://www.instagram.com/lesvotiersdelaurac/',
        alt: 'Affiche de la fête votive de Laurac-en-Vivarais'
    },
]