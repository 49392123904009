import React from 'react'
import { useState } from 'react'

function HeroTeeshirt() {

  const [selectedImage, setSelectedImage] = useState('/images/tee-front.webp'); // URL de l'image sélectionnée par défaut
  const [thumbnailActive, setThumbnailActive] = useState('Front'); // Miniature active par défaut
  const [isModalOpen, setIsModalOpen] = useState(false); // État de la modal
  const [statusMessage, setStatusMessage] = useState('');
  const [commandSend, setCommandSend] = useState(false);
  const [formData, setFormData] = useState({
    nom: '',
    tel: '',
    email: '',
    message: '',
    livraison: 'Récupération en Votive',
    xs: "0",
    s: "0",
    m: "0",
    l: "0",
    xl: "0",
    xxl: "0",
    condition: false
  });

  // Fonction pour ouvrir la modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Fonction pour gérer le changement de lieu de livraison
  const handleLocationChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      livraison: event.target.value
    }));
  };

  // Fonction pour afficher ou non le message de confirmation de commande
  const handleClose = () => {
    setCommandSend(false)
  }

  // Fonction pour changer l'image principale lorsqu'une miniature est cliquée
  const changeImage = (newImage, thumbnailName) => {
    setSelectedImage(newImage);
    setThumbnailActive(thumbnailName);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Vérifiez si au moins une des valeurs des tailles est différente de zéro
    const isSizeSelected = (formData.xs !== "0" || formData.s !== "0" || formData.m !== "0" || formData.l !== "0" || formData.xl !== "0" || formData.xxl !== "0");

    // Si aucune taille n'est sélectionnée, affichez un message d'erreur
    if (!isSizeSelected) {
      setStatusMessage('Veuillez sélectionner au moins une taille.');
      return; // Arrêtez l'exécution de la fonction handleSubmit
    }
    document.getElementById('send').disabled = true; // Désactiver le bouton

    // Création de l'objet FormData pour envoyer les données
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    // Appel AJAX
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/php/mail.php', true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // Utilisation d'une expression régulière pour extraire le statut de l'envoi
        const reponseOK = xhr.responseText.match(/La demande à été envoyé avec succès ! Fetevotive.fr vous remercie :\)/g);
        if ((reponseOK !== null && reponseOK.length === 1)) {
          // Appel AJAX
          const xhrClient = new XMLHttpRequest();
          xhrClient.open('POST', '/php/mail-confirmation-client.php', true);
          xhrClient.onreadystatechange = function () {
            if (xhrClient.readyState === 4 && xhrClient.status === 200) {
              const reponseConfirmationOK = xhrClient.responseText.match(/La demande à été envoyé avec succès ! Fetevotive.fr vous remercie :\)/g);
              if (reponseConfirmationOK !== null && reponseConfirmationOK.length === 1) {
                setCommandSend(true)
                setStatusMessage('Votre commande a été envoyé avec succès.');
                // Réinitialiser le formData
                setFormData({
                  nom: '',
                  tel: '',
                  email: '',
                  message: '',
                  livraison: 'Récupération en Votive',
                  xs: "0",
                  s: "0",
                  m: "0",
                  l: "0",
                  xl: "0",
                  xxl: "0",
                  condition: false,
                });

                // Effacer le message de statut après 3 secondes et réactivé le bouton send
                setTimeout(() => {
                  setStatusMessage('');
                  document.getElementById('send').disabled = false; // Réactiver le bouton
                }, 3000);
              } else {
                setStatusMessage('Une erreur s\'est produite lors de l\'envoi de la commande.');

                // Effacer le message de statut après 3 secondes et réactivé le bouton send
                setTimeout(() => {
                  document.getElementById('send').disabled = false; // Réactiver le bouton
                }, 3000);
              }
            }
          };
          xhrClient.send(formDataToSend);
        } else {
          setStatusMessage('Une erreur s\'est produite lors de l\'envoi de la commande.');

          // Effacer le message de statut après 3 secondes et réactivé le bouton send
          setTimeout(() => {
            document.getElementById('send').disabled = false; // Réactiver le bouton
          }, 3000);
        }
      }
    };
    xhr.send(formDataToSend);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };


  return (
    <div className='sm:bg-hero-background bg-hero-phone-background w-full bg-cover bg-center '>
      <div className="px-[4%] text-center w-full max-h-[1500px] lg:max-h-[1000px] min-h-[90vh] bg-black bg-opacity-50 text-white flex justify-between flex-col items-center">
        <div className='my-4'>
          <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg'>Commande ton Tee-Shirt</h1>
          <p className='text-xs'>achete un tee-shirt et gagne une bouteille de marquisette*</p>
        </div>
        <section className='flex justify-around w-full md:w-[80%] flex-wrap'>
          <div className='w-[30%] min-w-[300px] flex flex-col items-center'>
            <img src={selectedImage} alt='Tee-shirt' className='max-w-full cursor-pointer drop-shadow-xl' onClick={openModal} />
            <div className='mt-4 flex justify-center'>
              {/* Miniatures des images */}
              <div className={`teeshirt-thumbnail cursor-pointer w-[20%] ${thumbnailActive === 'Front' ? 'teeshirt-actif' : ''}`} onClick={() => changeImage('/images/tee-front.webp', 'Front')}>
                <img
                  src='/images/tee-front.webp'
                  alt='Tee-shirt front'
                />
              </div>
              <div className={`teeshirt-thumbnail cursor-pointer w-[20%] ${thumbnailActive === 'Back' ? 'teeshirt-actif' : ''}`} onClick={() => changeImage('/images/tee-back.webp', 'Back')}>
                <img
                  src='/images/tee-back.webp'
                  alt='Tee-shirt back'
                />
              </div>
              {/* Modal pour afficher l'image en grand */}
              {isModalOpen && (
                <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] sm:h-[90vh] w-full  flex items-center justify-center z-50" onClick={closeModal}>
                  <div className="bg-black bg-opacity-50 p-4 rounded-lg cursor-pointer">
                    <img src={selectedImage} alt="Tee-shirt" className="h-[100vh] lg:h-[90vh] object-contain" />
                  </div>
                </div>
              )}
            </div>
            <p className='text-3xl font-bold'>Tee-shirt: 15€</p>
            <p className='text-xs'>2€ pour la livraison à Aubenas ou à récupérer en votive sans frais *</p>
            <p className='text-xs'>* Conditions ci-dessous</p>
            <p className='text-center bg-white text-red-600 text-xs font-bold px-1 py-1 rounded-md mt-2'>Lavage du tee-shirt à 30°C et repassage à l'envers</p>
          </div>
          <div className=' w-[30%] min-w-[300px]'>
            <form onSubmit={handleSubmit}>
              <h2 className='text-2xl font-bold my-4 drop-shadow-lg'>1. Séléctionne la quantité par taille</h2>
              <div className='flex justify-around'>
                <div>
                  <div className='flex w-[100%] my-2 justify-between'>
                    <label htmlFor="xs" className='mr-4 font-bold'>XS :</label>
                    <select className='text-gray-800 px-2 w-16 rounded font-bold' name="xs" id="xs" value={formData.xs} onChange={handleChange}>
                      {[...Array(10).keys()].map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex w-[100%] my-2 justify-between'>
                    <label htmlFor="s" className='mr-4 font-bold'>S :</label>
                    <select className='text-gray-800 px-2 w-16 rounded font-bold' name="s" id="s" value={formData.s} onChange={handleChange}>
                      {[...Array(10).keys()].map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex w-[100%] my-2 justify-between'>
                    <label htmlFor="m" className='mr-4 font-bold'>M :</label>
                    <select className='text-gray-800 px-2 w-16 rounded font-bold' name="m" id="m" value={formData.m} onChange={handleChange}>
                      {[...Array(10).keys()].map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                <div className='flex w-[100%] my-2 justify-between'>
                    <label htmlFor="l" className='mr-4 font-bold'>L :</label>
                    <select className='text-gray-800 px-2 w-16 rounded font-bold' name="l" id="l" value={formData.l} onChange={handleChange}>
                      {[...Array(10).keys()].map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                  <div className='flex w-[100%] my-2 justify-between'>
                    <label htmlFor="xl" className='mr-4 font-bold'>XL :</label>
                    <select className='text-gray-800 px-2 w-16 rounded font-bold' name="xl" id="xl" value={formData.xl} onChange={handleChange}>
                      {[...Array(10).keys()].map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <h2 className='text-2xl font-bold my-4 drop-shadow-lg'>2. Remplis tes infos</h2>
              <div>
                <div className='flex justify-between w-[100%]'>
                  <div className='flex my-2 justify-between flex-wrap w-[49%]'>
                    <label htmlFor="" className='mr-4 font-bold'>Prénom Nom * :</label>
                    <input required type="text" className='text-gray-800 px-2 text-center rounded font-bold py-1' placeholder='Marco Vassal' name="nom" id="nom" value={formData.nom} onChange={handleChange} />
                  </div>
                  <div className='flex my-2 justify-between flex-wrap w-[49%]'>
                    <label htmlFor="" className='mr-4 font-bold'>Téléphone * :</label>
                    <input required type="tel" className='text-gray-800 px-2 text-center rounded font-bold py-1' placeholder='0644870471' name="tel" id="tel" value={formData.tel} onChange={handleChange} />
                  </div>
                </div>
                <div className='flex my-2 justify-between flex-wrap'>
                  <label htmlFor="" className='mr-4 font-bold'>E-mail * :</label>
                  <input required type="email" className='text-gray-800 px-2 text-center rounded font-bold py-1' placeholder='exemple@gmail.com' name="email" id="email" value={formData.email} onChange={handleChange} />
                </div>
                <div className='flex my-2 flex-wrap'>
                  <label htmlFor='location' className='block font-bold'>Retrait / Livraison :</label>
                  <select
                    aria-label="Livraison"
                    type='text'
                    id='livraison'
                    name='livraison'
                    className='text-gray-800 px-2 text-center rounded font-bold py-1'
                    value={formData.livraison} // La valeur est extraite de formData.livraison
                    onChange={handleLocationChange} // Appel à la fonction handleLocationChange lorsqu'une option est sélectionnée
                    required
                  >
                    <option value='Récupération en Votive'>En fête votive</option>
                    <option value='Aubenas'>Aubenas</option>
                    {/* <option value='Joyeuse'>Joyeuse</option>
                    <option value='Lablachère'>Lablachère</option>
                    <option value='Largentière'>Largentière</option>
                    <option value='Laurac-en-Vivarais'>Laurac-en-Vivarais</option>
                    <option value='Lavilledieu'>Lavilledieu</option>
                    <option value='Rosières'>Rosières</option>
                    <option value='Ruoms'>Ruoms</option>
                    <option value='Vals-les-Bains'>Vals-les-Bains</option>
                    <option value='Vinezac'>Vinezac</option>
                    <option value='Vogüé'>Vogüé</option> */}
                    <option value='Autre'>Autre</option>
                  </select>
                </div>
                <div className='flex my-2 justify-between flex-wrap'>
                  <label htmlFor="" className='mr-4 font-bold'>Message :</label>
                  <textarea type="text" className='text-gray-800 px-2 text-center rounded font-bold py-1' placeholder='Bonjour, j ai une question au niveau de ...' name="message" id="message" value={formData.message} onChange={handleChange} />
                </div>
              </div>
              <h2 className='text-2xl font-bold my-4 drop-shadow-lg'>3. Envoie ta demande</h2>
              <div className='flex'>
                <input type="checkbox" required id='conditon' name='condition' value='oui' checked={formData.condition} onChange={handleChange}/>
                <p className='text-xs'>J'ai pris connaissance du fonctionnement du jeu, des règles liés à cet achat et je m'engage à récupérer ma commande</p>
              </div>
              <button id='send' type='submit' className="w-[200px] my-2 hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-600 border border-blue-600 text-white hover:text-blue-600 font-bold py-2 px-4 rounded duration-300 ">Envoyer</button>
              <div className='relative h-10'>
                <p className='absolute left-[50%] translate-x-[-50%] w-full'>{statusMessage}</p>
              </div>
              {commandSend && (
                <div className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 duration-500 z-40' onClick={handleClose}>
                  <div className='max-w-xl mx-auto'>
                    <div className='bg-black bg-opacity-80 w-[90%] m-auto py-10 px-2 rounded-3xl relative'>
                      <h1 className='text-2xl font-bold mb-2 underline'>Merci de ta confiance</h1>
                      <p className='pb-2'>La demande à bien été envoyé</p>
                      <p>Tu devrais recevoir un mail de confirmation avec les détails de ta commande</p>
                      <p className='text-xs text-green-300 mt-3'>Regarde dans tes spams si tu ne le vois pas</p>
                      <p className='text-xs text-green-300 mt-3'>Si tu ne reçois rien, envoie nous un mail directement</p>
                      <button className='absolute top-0 right-0 text-white font-bold bg-red-500 px-3 py-1 rounded-bl rounded-tr-3xl' onClick={handleClose}>
                        X
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </section>
        <div className='flex'>
          <h2 onClick={() => window.scrollTo({ top: window.pageYOffset + (window.innerHeight * 0.901), behavior: 'smooth' })} className='flex justify-center animate-bounce items-center text-xl font-bold pb-0 mb-7 mt-7 sm:mb-0 sm:pb-8 sm:text-2xl hover:cursor-pointer'><img className='px-4' src="/images/Angle-double-down.svg" alt="" /> Comment ça fonctionne ?<img className='px-4' src="/images/Angle-double-down.svg" alt="" /></h2>

        </div>
      </div>


    </div >
  )
}

export default HeroTeeshirt