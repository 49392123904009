import React from 'react'

function Partenaire() {
    return (
        <div className='text-center'>
            <h1 className='text-3xl md:text-5xl font-bold drop-shadow-lg my-8 text-center'>Les Partenaires</h1>
            <div className='flex xl:w-[80%] md:justify-around overflow-x-auto snap-x snap-mandatory m-auto pb-10'>
                <div className='shrink-0 max-[430px]:w-[10%]'></div>  {/* Ajoute du padding à gauche */}
                <div className='snap-center shrink-0 max-[430px]:w-[80%] w-[300px] text-center flex flex-col items-center border-2 shadow-xl p-4 rounded-xl mx-2'>
                    <img src="/images/partenaires/big-dalle.webp" alt="" className='w-40 h-40 object-cover rounded-full' />
                    <h2 className='font-bold my-2'>Big Dalle</h2>
                    <div className='flex flex-col justify-between h-full'>
                        <p>Fast-Food de qualité utilisant des produits frais. En livraison autour d'Aubenas ou à emporter, vous pouvez commander par SMS au <a href="tel:0760258096" className='text-blue-600'>07.60.25.80.96</a> ou sur les réseaux: </p>
                        <div className='flex justify-around w-[60%] mt-2 mx-auto'>
                            <a href="https://www.snapchat.com/add/chez_lem07" aria-label="Compte Snapchat de Big Dalle" className='w-10 object-cover hover:scale-110'><img src="/images/partenaires/big-dalle-snap.webp" alt="" /></a>
                            <a href="https://www.instagram.com/big_dal07" aria-label="Compte Instagram de Big Dalle" className='w-10 object-cover hover:scale-110'><img src="/images/partenaires/instagram.webp" alt="" /></a>
                        </div>
                    </div>
                </div>
                <div className='snap-center shrink-0 max-[430px]:w-[80%] w-[300px] text-center flex flex-col items-center border-2 shadow-xl p-4 rounded-xl mx-2'>
                    <img src="/images/partenaires/arthur-pouchet.webp" alt="" className='w-40 h-40 object-cover rounded-full ' />
                    <h2 className='font-bold my-2'>Arthur Pouchet Photographie</h2>
                    <div className='flex flex-col justify-between h-full'>
                        <p>Photographe et vidéaste polyvalent, j'affectionne particulièrement shooter les sports d'endurance.
                            Disponible pour tout projet en Ardèche. Appréciez mon travail et contactez moi sur Instagram:</p>
                        <div className='flex justify-around w-[60%] mt-2 mx-auto'>
                            <a href="https://www.instagram.com/arthurpouchett/" aria-label="Compte Instagram de Big Dalle" className='w-10 object-cover hover:scale-110'><img src="/images/partenaires/instagram.webp" alt="" /></a>
                        </div>

                    </div>
                </div>
                <div className='snap-center shrink-0 max-[430px]:w-[80%] w-[300px] text-center flex flex-col items-center border-2 shadow-xl p-4 rounded-xl mx-2'>
                    <img src="/images/partenaires/solid-immo.webp" alt="" className='w-40 h-40 object-cover rounded-full ' />
                    <h2 className='font-bold my-2'>Solid Immo Jérôme Montérémal</h2>
                    <div className='flex flex-col justify-between h-full'>
                        <p>Retrouvez dans notre agence un service immobilier vraiment unique, où chaque décision est guidée par un souci de solidarité et de respect de l'environnement.  Vous pouvez me contacter au <a href="tel:0618987584" className='text-blue-600'>06.18.98.75.84</a> </p>
                        <div className='flex justify-around w-full mt-2'>
                            <a target='_blank' rel="noreferrer" href="https://www.solid-immo.com/mandataire-detail/7/monteremal/1" className="w-[150px] flex items-center h-[40px] justify-center hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-600 border border-blue-600 text-white hover:text-blue-600 font-bold rounded duration-300">Biens en vente</a>
                        </div>
                    </div>
                </div>
                <div className='snap-center shrink-0 max-[430px]:w-[80%] w-[300px] text-center flex flex-col items-center border-2 shadow-xl p-4 rounded-xl mx-2'>
                    <img src="/images/profile.webp" alt="" className='w-40 h-40 object-cover rounded-full ' />
                    <h2 className='font-bold my-2'>Marco Vassal</h2>
                    <div className='flex flex-col justify-between h-full'>
                        <p>Je suis développeur web et j'ai créé ce site. Besoin d'un site web pour booster votre entreprise ? Contactez-moi au <a href="tel:0644870471" className='text-blue-600'>06.44.87.04.71</a> ou retrouvez mon site : </p>
                        <div className='flex justify-around w-full mt-2'>
                            <a target='_blank' rel="noreferrer" href="https://marco-vassal.fr/" className="w-[150px] flex items-center h-[40px] justify-center hover:bg-white text-center text-sm min-[400px]:text-base bg-blue-600 border border-blue-600 text-white hover:text-blue-600 font-bold rounded duration-300">Mon Portfolio</a>
                        </div>
                    </div>
                </div>
                <div className='shrink-0 max-[430px]:w-[10%]'></div>  {/* Ajoute du padding à droite */}
            </div>

        </div>
    )
}

export default Partenaire